import React from "react";
import Header from "../../../components/header";
import HeroBanner from "../../../components/HeroBanner";
import Layout from "../../../components/layout";
import WithProviders from "../../../components/WithProviders";
import ProductWithImage from "../../../components/ProductWithImage";
import { Message } from "../../../lib/getMessage";

import OperationManagement from "../../images/pr-op-management.png";
import OperationManagement2 from "../../images/pr-op-management@2x.png";
import OperationManagement3 from "../../images/pr-op-management@3x.png";

import planningImg from "../../images/pr-op-planning.png";
import planningImg2 from "../../images/pr-op-planning@2x.png";
import planningImg3 from "../../images/pr-op-planning@3x.png";

import pickingImg from "../../images/pr-op-picking.png";
import pickingImg2 from "../../images/pr-op-picking@2x.png";
import pickingImg3 from "../../images/pr-op-picking@3x.png";

import packingImg from "../../images/pr-op-packing.png";
import packingImg2 from "../../images/pr-op-packing@2x.png";
import packingImg3 from "../../images/pr-op-packing@3x.png";

import realTimeUpdatesImg from "../../images/pr-op-real-updates.png";
import realTimeUpdatesImg2 from "../../images/pr-op-real-updates@2x.png";
import realTimeUpdatesImg3 from "../../images/pr-op-real-updates@3x.png";

import "./style.css";
import ListView from "../../../components/ListView";
const opManagementDesc = (isDirectionRTL) => (
  <ListView
    lists={[
      <Message dictKey="products.operationsmanagement.item1" />,
      <Message dictKey="products.operationsmanagement.item2" />,
    ]}
    isDirectionRTL={isDirectionRTL}
  />
);

const pickingDesc = (isDirectionRTL) => (
  <ListView
    heading={
      <Message dictKey="products.operationsmanagement.picking.description" />
    }
    lists={[
      <Message dictKey="products.operationsmanagement.picking.item1" />,
      <Message dictKey="products.operationsmanagement.picking.item2" />,
    ]}
    isDirectionRTL={isDirectionRTL}
  />
);

const selfUpdateDesc = (isDirectionRTL) => (
  <ListView
    lists={[
      <Message dictKey="products.operationsmanagement.selfupdatedesc.item1" />,
      <Message dictKey="products.operationsmanagement.selfupdatedesc.item2" />,
    ]}
    isDirectionRTL={isDirectionRTL}
  />
);

const OperationsManagement = (props) => {
  const { language, updateLanguage, direction } = props;
  const isDirectionRTL = direction === "rtl";
  return (
    <Layout
      direction={direction}
      showFooterForm
      language={language}
      location={props.location}
    >
      <div>
        <Header
          current="product"
          language={language}
          updateLanguage={updateLanguage}
          direction={direction}
        />
        <div className="pr-op-managmnt">
          <HeroBanner
            title={<Message dictKey="products.operationsmanagement.title" />}
            className={`pr-op-managmnt-banner ${
              isDirectionRTL && "pr-op-managmnt-banner-rtl"
            }`}
            isDirectionRTL={isDirectionRTL}
          />

          <section className="pr-op-managmnt-rprt">
            <ProductWithImage
              name="operation-management"
              className="operation-management"
              heading={
                <Message dictKey="products.operationsmanagement.heading" />
              }
              description={[
                {
                  type: "component",
                  val: opManagementDesc(isDirectionRTL),
                  id: 1,
                },
              ]}
              image={{
                normal: OperationManagement,
                xx: OperationManagement2,
                xxx: OperationManagement3,
              }}
              isDirectionRTL={isDirectionRTL}
            />
            <ProductWithImage
              name="planning"
              className="planning"
              heading={
                <Message dictKey="products.operationsmanagement.planning.heading" />
              }
              description={[
                {
                  type: "p",
                  val: (
                    <Message dictKey="products.operationsmanagement.planning.description" />
                  ),
                  id: 1,
                },
              ]}
              image={{
                normal: planningImg,
                xx: planningImg2,
                xxx: planningImg3,
              }}
              isDirectionRTL={isDirectionRTL}
            />
            <ProductWithImage
              name="picking"
              className="picking"
              heading={
                <Message dictKey="products.operationsmanagement.picking.heading" />
              }
              description={[
                {
                  type: "component",
                  val: pickingDesc(isDirectionRTL),
                  id: 1,
                },
              ]}
              image={{
                normal: pickingImg,
                xx: pickingImg2,
                xxx: pickingImg3,
              }}
              isDirectionRTL={isDirectionRTL}
            />

            <ProductWithImage
              name="packing"
              className="packing"
              heading={
                <Message dictKey="products.operationsmanagement.packing.heading" />
              }
              description={[
                {
                  type: "p",
                  val: (
                    <Message dictKey="products.operationsmanagement.packing.description" />
                  ),
                  id: 1,
                },
              ]}
              image={{
                normal: packingImg,
                xx: packingImg2,
                xxx: packingImg3,
              }}
              isDirectionRTL={isDirectionRTL}
            />
            <ProductWithImage
              name="self-updates"
              className="self-updates"
              heading={
                <Message dictKey="products.operationsmanagement.selfupdatedesc.heading" />
              }
              description={[
                {
                  type: "component",
                  val: selfUpdateDesc(isDirectionRTL),
                  id: 1,
                },
              ]}
              image={{
                normal: realTimeUpdatesImg,
                xx: realTimeUpdatesImg2,
                xxx: realTimeUpdatesImg3,
              }}
              isDirectionRTL={isDirectionRTL}
            />
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default WithProviders(OperationsManagement);
